
import '../css/App.css'
import '../css/Header.css'
import '../css/Home.css'
import Header from '../components/Header'

import React, { useState, useEffect } from 'react';

import { format } from 'date-fns'
import frLocale from 'date-fns/locale/fr'

import Service_Api from '../services/Api'
import { File } from '../services/object/File'

import contactLogo from '../img/contact-logo.png'
import fileLogo from '../img/file-logo.png'
import historyLogo from '../img/history-logo.png'
import importLogo from '../img/import-logo.png'

import { Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined'

interface FilesByStatut {
	[statut: string]: File[];
}

export default function HomePage() {
	const Api = new Service_Api()
	let [files, setFiles] = useState<any[]>([])
	const [filesByStatut, setFilesByStatut] = useState<{ [statut: string]: File[] }>({});
	const [openItems, setOpenItems] = useState<{ [statut: string]: boolean }>({});

	const handleClick = (statut: string) => {
		setOpenItems((prevOpenItems) => ({
			...prevOpenItems,
			[statut]: !prevOpenItems[statut],
		}));
	};

	// get datas

	const fetchFilesImportedToday = async () => {
		try {
			const response = await Api.get("File/getFilesImportedToday")
			setFiles(response.data.map(
				(file: File) => {
					return {
						id: file.id,
						libelle: file.libelle,
						type_donnee: file.type_donnee,
						source_donnee: file.source_donnee,
						est_quotidien: file.est_quotidien,
						nb_ligne: file.nb_ligne,
						date_debut: file.date_debut,
						statut: file.statut,
					}
				}
			))
		} catch (error) {
			console.error('Erreur lors de la récupération des données')
		}
	}

	useEffect(() => {
		fetchFilesImportedToday()
	}, [])

	useEffect(() => {
		// Tri des fichiers par statut
		const filesByStatutData = files.reduce((acc: FilesByStatut, file) => {
			const statut = file.statut;
			if (!acc[statut]) {
				acc[statut] = [];
			}
			acc[statut].push(file);
			return acc;
		}, {} as FilesByStatut);
		console.log('Fichiers triés par statut :', filesByStatutData);

		// Mettre à jour l'état avec les fichiers triés par statut
		setFilesByStatut(filesByStatutData);
	}, [files]);

	console.log(filesByStatut)

	return <div>
		<Header pageName={"home"} />
		<div id='homePage' className='body'>

			<div className='card-box' >
				<Box className='card-row'>
					<Link to="/Contact" className='card'>
						<img src={contactLogo} alt="Logo" className="logo" />
						<h2>Référentiel des contacts uniques</h2>
					</Link>
					<Link to="/File" className='card'>
						<img src={fileLogo} alt="Logo" className="logo" />
						<h2>Rapports d’intégration</h2>
					</Link>
					<Link to="/History" className='card'>
						<img src={historyLogo} alt="Logo" className="logo" />
						<h2>Historique</h2>
					</Link>
				</Box>
				<Box className='card-row'>
					<Link to="/" className='card'>
						<img src={importLogo} alt="Logo" className="logo" />
						<h2>Import manuel</h2>
					</Link>
				</Box>
			</div>


			<div className='home-infos'>
				{filesByStatut.length ? 
					<List aria-labelledby="nested-list-subheader">
						{Object.entries(filesByStatut).map(([statut, files], index) => (
							<div className='list' key={index}>
								{files.length > 0 ? (
									<React.Fragment>
										<div className='header-list'>
											{(statut === "KO" ? <HighlightOffIcon style={{ fill: 'var(--red)' }} /> : "")}
											{(statut === "EN_COURS" ? <HourglassEmptyOutlinedIcon style={{ fill: 'var(--orange)' }} /> : "")}
											<ListItemText primary={(statut === "KO" ? "Echec d'import" : "Import en cours")} />
										</div>

										<List component="div" disablePadding>
											{files.slice(0, 5).map((file, fileIndex) => (
												<ListItemText key={fileIndex}>
													<p>{file.libelle}</p>
													<p>{format(new Date(file.date_debut), "dd MMMM yyyy HH:mm", { locale: frLocale })}</p>
												</ListItemText>
											))}
											<ListItemText className='voir-plus'>
												{/* TODO: voir plus filtrés */}
												<Link to="/File" className='card'>Voir plus</Link>
											</ListItemText>
										</List>
									</React.Fragment>
								) : (
									<p>Aucun échec d'import ni import en cours</p>
								)}
							</div>
						))}
					</List>
				: <></>}
			</div>

		</div>
	</div>
}
