import '../css/App.css'
import '../css/Header.css'

import { useEffect, useState } from 'react'

import Header from '../components/Header'
import Datatable from '../components/Datatable'
import Service_Api from '../services/Api'
import { File } from '../services/object/File'
import { Filters } from '../services/object/Filters'

const FilePage: React.FC = () => {
    const Api = new Service_Api()
    let pageName = "File"
    let [files, setFiles] = useState<any[]>([])
    let [statuts, setStatuts] = useState<any[]>([])
    const [statutObjects, setStatutObjects] = useState<{ name: string }[] | undefined>(undefined)
    let [types, setTypes] = useState<any[]>([])
    const [typesObjects, setTypesObjects] = useState<{ name: string }[] | undefined>(undefined)
    let [filters, setFilters] = useState<Filters[]>([])

    // fetch datas

    const fetchFiles = async () => {
        try {
            const response = await Api.get("File/")
            setFiles(response.data.map(
                (file: File) => {
                    return {
                        id: file.id,
                        libelle: file.libelle,
                        type_donnee: file.type_donnee,
                        source_donnee: file.source_donnee,
                        est_quotidien: file.est_quotidien,
                        nb_ligne: file.nb_ligne,
                        date_debut: file.date_debut,
                        statut: file.statut,
                    }
                }
            ))
        } catch (error) {
            console.error('Erreur lors de la récupération des données')
        }
    }

        // fetch options for selects

    const fetchDistinctStatuts = async () => {
        try {
            const response = await Api.get("File/getDistinctFileStatut")
            setStatuts(response.data)

            const objects = response.data.map((statut: string) => ({ name: statut }))
            setStatutObjects(objects)
        } catch (error) {
            console.error('Erreur lors de la récupération des données')
        }
    }

    const fetchDistinctTypes = async () => {
        try {
            const response = await Api.get("File/getDistinctFileType")
            setTypes(response.data)

            const objects = response.data.map((type: string) => ({ name: type }))
            setTypesObjects(objects)
        } catch (error) {
            console.error('Erreur lors de la récupération des données')
        }
    }

	const fetchData = async () => {
        await fetchFiles()
        await fetchDistinctStatuts()
        await fetchDistinctTypes()
    }

	useEffect(() => {
        fetchData()
    }, [])

    // set filters

    useEffect(() => {
        const statutsObject = statutObjects ?? []
        const typesObject = typesObjects ?? []

        const newFilters: Filters[] = [
            {
                name: 'libelle',
                title: "Libellé",
                type: 'text',
            },
            {
                name: 'statut',
                title: "Statut",
                type: 'select',
                values: statutsObject
            },
            {
                name: 'type_donnee',
                title: "Type",
                type: 'select',
                values: typesObject
            },
        ]

        setFilters(newFilters)
    }, [statutObjects, typesObjects])

	return (
		<div>
			<Header pageName={pageName} />
			<div className="body" id='filePage'>
				<div className='flex'>
					<h2>Rapports d'intégration</h2>
				</div>

				< Datatable pageName={pageName} data={files} filters={filters} />
			</div>
		</div>
	)
}

export default FilePage

