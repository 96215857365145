
import { File } from '../object/File'

export const frenchFileAttributes: Record<keyof File, string> = {
	id: "Identifiant",
	libelle: "Libellé",
	type_donnee: "Type",
	source_donnee: "Source",
	est_quotidien: "Est quotidien",
	nb_ligne: "Nombre de lignes",
	date_debut: "Date de début",
	statut: "Statut",
}
