
import { Contact } from '../object/Contact'

export const frenchContactAttributes: Record<keyof Contact, string> = {
	id: "Identifiant",
	ref_transaction: "Référence transaction",
	nom: "Nom",
	prenom: "Prénom",
	email: "Email",
	tel: "Téléphone",
	tel_fixe: "Téléphone fixe",
	tel_mobile: "Téléphone mobile",
	adresse: "Adresse",
	code_postal: "Code postal",
	ville: "Ville",
	npai: "NPAI",
	opt_in_adresse: "Optin Adresse",
	opt_in_email: "Optin Email",
	opt_in_tel_domicile: "Optin téléphone domicile",
	opt_in_tel_mobile: "Optin téléphone mobile",
	infos_vers_pdv: "Infos vers point de vente",
	infos_vers_marque: "Infos vers marque",
	infos_vers_partenaire: "Infos vers partenaire",
}
