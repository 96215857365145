import '../css/Filters.css'

import React, { useEffect } from 'react'
import useState from 'react-usestateref'

import { THEME } from '../Constants'
import { Filters } from '../services/object/Filters'
import { Contact } from '../services/object/Contact'
import { frenchContactAttributes } from '../services/object/frenchContactAttributes'
import { frenchFileAttributes } from '../services/object/frenchFileAttributes'
import { History } from '../services/object/History'
import { File } from '../services/object/File'

import Service_Api from '../services/Api'

import { ListItem, TextField, MenuItem, Select } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import ChevronrightIcon from '@mui/icons-material/ChevronRight'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress';

type Anchor = 'right'

interface FiltersComponentProps<T> {
    pageName: string;
    filters: Filters[];
    setRows?: React.Dispatch<React.SetStateAction<T[]>>
    setHistoryData?: React.Dispatch<React.SetStateAction<History>>
}

interface FilterSummary {
    key: string;
    value: string;
    frenchTranslation: string;
}

const FiltersComponent = <T extends Contact | File | History>({ pageName, filters, setRows, setHistoryData }: FiltersComponentProps<T>) => {

    const [loading, setLoading] = useState(false)
    const [summaryPhrase, setSummaryPhrase] = useState<string[]>([])

    // actions filters drawer

    const Api = new Service_Api()
    const [state, setState] = useState({ right: false, })

    const closeDrawer = () => {
        const event = new MouseEvent('click') as unknown as React.MouseEvent<Element, MouseEvent>
        toggleDrawer('right', false)(event)
    }

    useEffect(() => {
        closeDrawer()
    }, [setRows, setHistoryData])

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent<Element>).key === 'Tab' ||
                (event as React.KeyboardEvent<Element>).key === 'Shift')
        ) {
            return
        }

        setState({ ...state, [anchor]: open });
    }

    // actions filters inputs

    const [selectedFilters, setSelectedFilters, selectedFiltersRef] = useState<{ [key: string]: string }>({})
    const [disabledBtn, setDisabledBtn] = useState(true)

    const checkIfFilters = () => {
        let filters = selectedFiltersRef.current

        if (Object.keys(filters).length > 0) {
            setDisabledBtn(false)
        } else {
            setDisabledBtn(true)
        }
    }
    const handleFieldChange = (name: string, value: string) => {
        if (value) {
            setSelectedFilters(prevFilters => ({ ...prevFilters, [name]: value }))
        } else {
            const updatedFilters = { ...selectedFilters }
            delete updatedFilters[name]
            setSelectedFilters(updatedFilters)
        }
        checkIfFilters()
    }

    const handleReset = (isApplied: boolean = false) => {
        let filterBox = document.getElementById("filters_box_" + pageName)

        if (filterBox) {
            let T_selects = filterBox.getElementsByTagName("select")
            for (let i = 0; i < T_selects.length; i++) {
                T_selects[i].value = ""
            }
        }
        setSelectedFilters({})
        checkIfFilters()

        if (isApplied) {
            handleApplyFilters(true)
        }
    }

    const handleApplyFilters = async (isReset: boolean = false) => {
        setLoading(true)

        let apiString = ""
        let frenchString = []
        let frenchStringJoined = ""

        if(!isReset){
            for (let [key, value] of Object.entries(selectedFilters)) {
                if (value) {
                    const frenchKey = (pageName === "Contact" ? frenchContactAttributes[key] : (frenchFileAttributes as any)[key]) || key;
                    let translatedValue = value;
                    if (key === "tel") {
                        apiString += `${key}=${"33" + value.slice(1)}&`
                    } else if (key === "adresse" || key === "ville") {
                        apiString += `${key}=${value.toUpperCase()}&`
                        translatedValue = value.toUpperCase()
                    } else {
                        apiString += `${key}=${value}&`
                    }
                    frenchString.push(`${frenchKey} <span>"${translatedValue}"</span>`)
                }
            }
    
            apiString = apiString.slice(0, -1)
            frenchStringJoined = frenchString.join(' - ')
        }

        try {
            let response = null

            if (apiString) {
                response = await Api.get(pageName + "/get" + pageName + "ByFilters", apiString)
                setSummaryPhrase([frenchStringJoined])
            } else {
                response = await Api.get(pageName + "/")
                setSummaryPhrase([])
            }

            if (setRows) {
                if(response){
                    if (pageName === "Contact") {
                        setRows(response.data.map(
                            (contact: any) => {
                                return {
                                    id: contact.id,
                                    ref_transaction: contact.ref_transaction,
                                    nom: contact.nom,
                                    prenom: contact.prenom,
                                    email: contact.email,
                                    tel_fixe: contact.tel_fixe,
                                    tel_mobile: contact.tel_mobile,
                                    adresse: contact.adresse,
                                    code_postal: contact.code_postal,
                                    ville: contact.ville,
                                    npai: contact.npai,
                                    opt_in_adresse: contact.opt_in_adresse,
                                    opt_in_email: contact.opt_in_email,
                                    opt_in_tel_domicile: contact.opt_in_tel_domicile,
                                    opt_in_tel_mobile: contact.opt_in_tel_mobile,
                                    infos_vers_pdv: contact.infos_vers_pdv,
                                    infos_vers_marque: contact.infos_vers_marque,
                                    infos_vers_partenaire: contact.infos_vers_partenaire
                                }
                            }
                        ))
                    } else if (pageName === "File") {
                        setRows(response.data.map(
                            (file: any) => {
                                return {
                                    id: file.id,
                                    libelle: file.libelle,
                                    type_donnee: file.type_donnee,
                                    source_donnee: file.source_donnee,
                                    est_quotidien: file.est_quotidien,
                                    nb_ligne: file.nb_ligne,
                                    date_debut: file.date_debut,
                                    statut: file.statut,
                                }
                            }
                        ))
                    }
                } else {
                    setRows([])
                }
            } else if (setHistoryData) {
                setHistoryData(response?.data)
            }

            closeDrawer()
        } catch (error) {
            console.error('Erreur lors de la récupération des données')
        }
        setLoading(false)
    }

    return (
        <div id='filtersBox'>
            {loading && (<CircularProgress className='loading' />)}

            <React.Fragment key={'right'}>
                <ThemeProvider theme={THEME}>
                    <div className='button-box'>
                        <Button onClick={toggleDrawer('right', true)} color="jraBlue" variant="contained">Filtrer</Button>
                    </div>

                    {summaryPhrase.length > 0 && (
                        <div id='summaryPhrase'>
                            <p>
                                {summaryPhrase.map((phrase, index) => (
                                    <span key={index} dangerouslySetInnerHTML={{ __html: phrase }}></span>
                                ))}
                            </p>
                            <Button
                                variant="outlined"
                                color="jraBlue"
                                onClick={() => handleReset(true)}
                            >
                                Réinitialiser
                            </Button>
                        </div>
                    )}

                    <Drawer
                        anchor={'right'}
                        open={state['right']}
                        onClose={toggleDrawer('right', false)}
                    >
                        <Box
                            sx={{ width: 300 }}
                            className="filters_box"
                            id={"filters_box_" + pageName}
                        >
                            <div className='filters-header'>
                                <IconButton onClick={toggleDrawer('right', false)}>
                                    <ChevronrightIcon sx={{ color: "#000" }} />
                                </IconButton>
                            </div>

                            <List className='scroll'>
                                {filters.map((filterObject, index) => {
                                    let filterObjectName = filterObject.name

                                    return (
                                        <ListItem
                                            key={index}
                                            disablePadding
                                            id={`filter_${pageName}_${filterObjectName}`}
                                        >
                                            {filterObject.type === 'select' && filterObject.values !== undefined ? (
                                                Array.isArray(filterObject.values) ? (
                                                    <FormControl fullWidth>
                                                        <InputLabel>{filterObject.title}</InputLabel>
                                                        <Select
                                                            label={filterObject.title}
                                                            value={selectedFilters[filterObjectName] || ''}
                                                            onChange={(e) => handleFieldChange(filterObjectName, e.target.value)}
                                                        >
                                                            <MenuItem key={0} value="">
                                                                --- Aucun ---
                                                            </MenuItem>
                                                            {filterObject.values?.map((value, valueIndex) => (
                                                                <MenuItem key={valueIndex} value={(value.id ? value.id : value.name)}>
                                                                    {value.name || ''}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                ) : null
                                            ) : filterObject.type === 'date' && pageName === 'History' ? (
                                                <TextField
                                                    label={filterObject.title}
                                                    type="date"
                                                    value={selectedFilters[filterObjectName] || ''}
                                                    onChange={(e) => handleFieldChange(filterObjectName, e.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            ) : (
                                                <TextField
                                                    id={`filter_${pageName}_${filterObjectName}`}
                                                    label={filterObject.title}
                                                    variant="outlined"
                                                    value={selectedFilters[filterObjectName as string] || ''}
                                                    onChange={(e) => handleFieldChange(filterObjectName as string, e.target.value)}
                                                />
                                            )}
                                        </ListItem>
                                    );
                                })}
                            </List>
                            <div className='btn-box flex'>
                                <Button
                                    variant="outlined"
                                    color="jraBlue"
                                    onClick={() => handleReset()}
                                    disabled={disabledBtn ? true : false}
                                >Réinitialiser</Button>
                                <Button
                                    variant="contained"
                                    color="jraBlue"
                                    id="apply_filters"
                                    onClick={() => handleApplyFilters()}
                                >Valider</Button>
                            </div>
                        </Box>
                    </Drawer>
                </ThemeProvider>
            </React.Fragment>
        </div>
    )
}

export default FiltersComponent;