import { 
	DataGrid, 
	GridColDef,
	GridRenderCellParams
} from '@mui/x-data-grid/'
import '../css/Datatable.css'

import { useEffect, useState } from 'react'

import { Filters } from '../services/object/Filters'
import { Contact } from '../services/object/Contact'
import { File } from '../services/object/File'
import Service_Api from '../services/Api'
import FiltersComponent from '../components/Filters'
import { formatDate } from '../Constants'
import arrow from '../img/arrow.png'

import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined'


interface DatatableProps {
    pageName: string;
    data: any[];
    filters: Filters[];
    onRowClick?: (params: any, event: any, details: any) => void;
}

export default function Datatable({ pageName, data, filters, onRowClick }: DatatableProps) {
	const Api = new Service_Api()
	let [rows, setRows] = useState<(Contact | File)[]>([])

	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleCloseModal = () => {
		setIsModalOpen(false)
	}

	useEffect(() => { 
		setRows(data) 
	}, [data])

	// format data

	function formatPhone(params: any) {
		return (params.value ? "0" + params.value.slice(2) : "")
	}
	function getChipProps(params: any) {
		if (params.value === true || params.value === "OK") {
			return {
				icon: <CheckCircleOutlineIcon style={{ fill: "var(--green)" }} />
			};
		} else if (params.value === false || params.value === "KO")  {
			return {
				icon: <HighlightOffIcon style={{ fill: "var(--red)" }} />
			}
		} else {
			return {
				icon: <DoNotDisturbAltOutlinedIcon style={{ fill: "var(--light-grey)" }} />
			}
		}
	}

	// Construct columns

	const columnsContact: GridColDef[] = [
		{
			field: 'id',
			headerName: 'ID contact',
			disableColumnMenu: true,
			minWidth: 50,
			headerAlign: 'left',
			align: 'left'
		},
		{
			field: 'ref_transaction',
			headerName: 'ID transaction',
			disableColumnMenu: true,
			minWidth: 130,
			headerAlign: 'left',
			align: 'left'
		},
		{
			field: 'nom',
			headerName: 'Nom',
			disableColumnMenu: true,
			minWidth: 170,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'prenom',
			headerName: 'Prénom',
			disableColumnMenu: true,
			minWidth: 130,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'email',
			headerName: 'Email',
			disableColumnMenu: true,
			minWidth: 200,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'tel_mobile',
			headerName: 'Téléphone mobile',
			disableColumnMenu: true,
			minWidth: 150,
			headerAlign: 'left',
			align: 'left',
			renderCell: (params) => {
				return formatPhone({ value: params.value });
			}
		},
		{
			field: 'tel_fixe',
			headerName: 'Téléphone fixe',
			disableColumnMenu: true,
			minWidth: 150,
			headerAlign: 'left',
			align: 'left',
			renderCell: (params) => {
				return formatPhone({ value: params.value });
			}
		},
		{
			field: 'adresse',
			headerName: 'Adresse',
			disableColumnMenu: true,
			minWidth: 200,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'ville',
			headerName: 'Ville',
			disableColumnMenu: true,
			minWidth: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'code_postal',
			headerName: 'Code postal',
			disableColumnMenu: true,
			minWidth: 80,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'npai',
			headerName: 'NPAI',
			disableColumnMenu: true,
			minWidth: 160,
			headerAlign: 'left',
			align: 'left',
			renderCell: (params) => {
				return <Chip variant="outlined" {...getChipProps(params)} />;
			}
		},
		{
			field: 'opt_in_adresse',
			headerName: 'Optin adresse',
			disableColumnMenu: true,
			minWidth: 160,
			headerAlign: 'left',
			align: 'left',
			renderCell: (params) => {
				return <Chip variant="outlined" {...getChipProps(params)} />;
			}
		},
		{
			field: 'opt_in_email',
			headerName: 'Optin email',
			disableColumnMenu: true,
			minWidth: 160,
			headerAlign: 'left',
			align: 'left',
			renderCell: (params) => {
				return <Chip variant="outlined" {...getChipProps(params)} />;
			}
		},
		{
			field: 'opt_in_tel_domicile',
			headerName: 'Optin tel domicile',
			disableColumnMenu: true,
			minWidth: 160,
			headerAlign: 'left',
			align: 'left',
			renderCell: (params) => {
				return <Chip variant="outlined" {...getChipProps(params)} />;
			}
		},
		{
			field: 'opt_in_tel_mobile',
			headerName: 'Optin tel mobile',
			disableColumnMenu: true,
			minWidth: 160,
			headerAlign: 'left',
			align: 'left',
			renderCell: (params) => {
				return <Chip variant="outlined" {...getChipProps(params)} />;
			}
		},
		{
			field: 'infos_vers_pdv',
			headerName: 'Infos vers pdv',
			disableColumnMenu: true,
			minWidth: 160,
			headerAlign: 'left',
			align: 'left',
			renderCell: (params) => {
				return <Chip variant="outlined" {...getChipProps(params)} />;
			}
		},
		{
			field: 'infos_vers_partenaire',
			headerName: 'Infos vers partenaire',
			disableColumnMenu: true,
			minWidth: 160,
			headerAlign: 'left',
			align: 'left',
			renderCell: (params) => {
				return <Chip variant="outlined" {...getChipProps(params)} />;
			}
		},
		{
			field: 'infos_vers_marque',
			headerName: 'Infos vers marque',
			disableColumnMenu: true,
			minWidth: 160,
			headerAlign: 'left',
			align: 'left',
			renderCell: (params) => {
				return <Chip variant="outlined" {...getChipProps(params)} />;
			}
		},
	]
	const columnsFile: GridColDef[] = [
		{
			field: 'id',
			headerName: 'ID',
			disableColumnMenu: true,
			minWidth: 50,
			headerAlign: 'left',
			align: 'left'
		},
		{
			field: 'statut',
			headerName: 'Statut',
			disableColumnMenu: true,
			minWidth: 120,
			headerAlign: 'left',
			align: 'left',
			renderCell: (params) => {
				return <Chip variant="outlined" {...getChipProps(params)} />;
			}
		},
		{
			field: 'libelle',
			headerName: 'Libellé',
			disableColumnMenu: true,
			minWidth: 250,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'type_donnee',
			headerName: 'Type donnée',
			disableColumnMenu: true,
			minWidth: 200,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'source_donnee',
			headerName: 'Source donnée',
			disableColumnMenu: true,
			minWidth: 200,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'date_debut',
			headerName: 'Date',
			disableColumnMenu: true,
			minWidth: 200,
			headerAlign: 'left',
			align: 'left',
			renderCell: (params: GridRenderCellParams) => {
				return formatDate(params);
			}
		},
		{
			field: 'nb_ligne',
			headerName: 'Nb lignes',
			disableColumnMenu: true,
			minWidth: 120,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'est_quotidien',
			headerName: 'Est quotidien',
			disableColumnMenu: true,
			minWidth: 120,
			headerAlign: 'left',
			align: 'left',
			renderCell: (params) => {
				return <Chip variant="outlined" {...getChipProps(params)} />;
			}
		},
	]

	// delete duplicates

	rows = rows.filter((value, index, self) =>
		index === self.findIndex((t) => (
			t.id === value.id
		))
	)

	// set view

	return (
		<Box sx={{ height: 400 }}>
			<FiltersComponent<Contact | File> pageName={pageName} filters={filters} setRows={setRows} />

			{ (rows && rows.length > 0) ?
				<DataGrid
					getRowId={(rows) => rows.id}
					columns={(pageName === "Contact" ? columnsContact : columnsFile)}
					rows={rows}
					autoHeight {...rows} 
					onRowClick={onRowClick}
					initialState={{
						pagination: {
							paginationModel: { pageSize: 10 },
						},
						sorting: {
							sortModel: [{ field: 'statut', sort: 'asc' }],
						},
					}}
					disableRowSelectionOnClick
					className='table'
				/>
			: 
			<div className='no-data'>
				{ pageName == "Contact" ?
					<div>
						<p>
							<span>Ici, vous trouverez l'intégralité du Référenciel des contacts uniques.</span>
							<span>Pour commencer, veuillez séléctionner des filtres.</span>
						</p>
						<div>
							<img src={arrow} alt="Flèche" className="arrow"/>
						</div>
					</div>
				:
					"Aucun résultat"
				}
			</div>
			}
		</Box>
	)
}