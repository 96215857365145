import '../css/Footer.css'
import React, { useState } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

export default function Footer() {
    const [displayFooter, setDisplayFooter] = useState(true);

    const onClose = () => {
        setDisplayFooter(false);
    }

    return (
        <div style={{ display: displayFooter ? 'block' : 'none' }}>
            <BottomNavigation className="footer">
                <p>Le Référenciel Contact Unique traite plus d'un million d'enregistrements. Pensez à utiliser les filtres pour réduire le temps de chargement.</p>
                <button className='close' onClick={onClose}>
                    <CloseOutlinedIcon />
                </button>
            </BottomNavigation>
        </div>
    )
}
