import '../css/App.css'
import '../css/Header.css'

import { useEffect, useState } from 'react'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Datatable from '../components/Datatable'
import Service_Api from '../services/Api'
import ModalContact from '../components/ModalContact';

import { Contact } from '../services/object/Contact'
import { Filters } from '../services/object/Filters'

import Alert from '@mui/material/Alert';

import {
	GridCallbackDetails,
	GridRowParams,
	MuiEvent
} from '@mui/x-data-grid/'

type Severity = "error" | "success" | "info" | "warning" | undefined;

const ContactPage: React.FC = () => {
	const Api = new Service_Api()
	let pageName = "Contact"
	let [data, setData] = useState<any[]>([])

	// get datas

	// const fetchContacts = async () => {
	// 	try {
	// 		const response = await Api.get("Contact/")
	// 		setData(response.data.map(
	// 			(contact: Contact) => {
	// 				return {
	// 					id: contact.id,
	// 					ref_transaction: contact.ref_transaction,
	// 					nom: contact.nom,
	// 					prenom: contact.prenom,
	// 					email: contact.email,
	// 					tel_fixe: contact.tel_fixe,
	// 					tel_mobile: contact.tel_mobile,
	// 					adresse: contact.adresse,
	// 					code_postal: contact.code_postal,
	// 					ville: contact.ville,
	// 					npai: contact.npai,
	// 					opt_in_adresse: contact.opt_in_adresse,
	// 					opt_in_email: contact.opt_in_email,
	// 					opt_in_tel_domicile: contact.opt_in_tel_domicile,
	// 					opt_in_tel_mobile: contact.opt_in_tel_mobile,
	// 					infos_vers_pdv: contact.infos_vers_pdv,
	// 					infos_vers_marque: contact.infos_vers_marque,
	// 					infos_vers_partenaire: contact.infos_vers_partenaire
	// 				}
	// 			}
	// 		))
	// 		console.log('Données récupérées avec succès', response)
	// 	} catch (error) {
	// 		console.error('Erreur lors de la récupération des données')
	// 	}
	// }

	// useEffect(() => {
	// 	fetchContacts()
	// }, [])

	// set filters

	const filters: Filters[] = [
		{ name: 'id', title: "Contact ID", type: 'input' },
		{ name: 'ref_transaction', title: "Transaction ID", type: 'input' },
		{ name: 'nom', title: "Nom", type: 'input' },
		{ name: 'prenom', title: "Prénom", type: 'input' },
		{ name: 'email', title: "Email", type: 'input' },
		{ name: 'tel', title: "Téléphone", type: 'input' },
		{ name: 'adresse', title: "Adresse", type: 'input' },
		{ name: 'code_postal', title: "Code postal", type: 'input' },
		{ name: 'ville', title: "Ville", type: 'input' }
	]

	// actions

	const [formContact, setFormContact] = useState<Contact>()
	const [contactId, setContactId] = useState<string>()
	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleRowClick = async (params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => {
		const Api = new Service_Api()

		try {
			const response = await Api.get('Contact/', 'id=' + params.id)

			setFormContact({
				id: response.data.id,
				ref_transaction: response.data.ref_transaction,
				nom: response.data.nom,
				prenom: response.data.prenom,
				email: response.data.email,
				tel_fixe: response.data.tel_fixe,
				tel_mobile: response.data.tel_mobile,
				adresse: response.data.adresse,
				code_postal: response.data.code_postal,
				ville: response.data.ville,
				npai: response.data.npai,
				opt_in_adresse: response.data.opt_in_adresse,
				opt_in_email: response.data.opt_in_email,
				opt_in_tel_domicile: response.data.opt_in_tel_domicile,
				opt_in_tel_mobile: response.data.opt_in_tel_mobile,
				infos_vers_pdv: response.data.infos_vers_pdv,
				infos_vers_marque: response.data.infos_vers_marque,
				infos_vers_partenaire: response.data.infos_vers_partenaire
			})
			setContactId(response.data.id)
			setIsModalOpen(true)
		} catch (error) {
			console.error('Erreur lors de la récupération des données')
		}
	}

	const [alert, setAlert] = useState(false)
	const [alertContent, setAlertContent] = useState('')
	const [alertSeverity, setAlertSeverity] = useState('')

	const handleCloseModal = () => {
		setIsModalOpen(false);
		setAlert(false)
		setAlertContent('')
	}

	function renderAlert(severity: string, content: string) {
		setAlertContent(content)
		setAlertSeverity(severity)
		setAlert(true)

		setTimeout(() => {
			setAlert(false)
			setAlertSeverity('')
			setAlertContent('')
		}, 5000)
	}

	function setCustomAlert(severity: Severity, text = "Sorry"){
		return <Alert style={{width:'50%'}} severity={severity}> {text}</Alert>
	}

	return (
		<div>
			<Header pageName={pageName} />
			<div className="body" id='contactPage'>
				<div className='flex'>
					<h2>RCU</h2>
				</div>

				<div className='alert'>
					{alert ? setCustomAlert(alertSeverity as Severity, alertContent) : <></>}
				</div>

				<Datatable pageName={pageName} data={data} filters={filters} onRowClick={handleRowClick} />
				
				{ isModalOpen && formContact && (
					<ModalContact
						onClose={handleCloseModal}
						isModalOpen={isModalOpen}
						formContact={formContact}
						onSave={() => renderAlert('success', 'Contact mis à jour avec succès !')}
						onSaveError={() => renderAlert('error', 'Erreur lors de la modification du contact')}
					/>
				)}
				
				<Footer />
			</div>
		</div>
	)
}

export default ContactPage

