import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import ContactPage from './pages/ContactPage';
import FilePage from './pages/FilePage';
import HistoryPage from './pages/HistoryPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
        <Router>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/Contact" element={<ContactPage />} />
				<Route path="/File" element={<FilePage />} />
				<Route path="/History" element={<HistoryPage />} />
			</Routes>
		</Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
