const BASE_API_URL = "https://api-rcu.dpo-jra.fr/jra-api-rcu/index.php/";
const JRA_API_URL = 'https://dev2.jra.tools/jra-api-dpo/index.php/';

interface RequestOptions {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    url: string;
    query?: string;
    headers?: Record<string, string>;
    body?: Record<string, any>;
}

// Nouvelle interface pour les options flexibles
interface FlexibleRequestOptions {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    url: string;
    query?: string;
    headers?: Record<string, string>;
    // Le corps peut être soit un objet soit une chaîne de caractères
    body?: Record<string, any> | string;
}

export default class Service_Api {
    token: string | null;

    constructor() {
        this.token = localStorage.getItem("token");
        if (this.token) {
            this.token = JSON.parse(this.token);
        }
    }

    async request(options: FlexibleRequestOptions) {
        let query = "";
        if (options.query) {
            query = "?" + options.query;
        } else {
            query = new URLSearchParams(options.query || {}).toString();
            if (query !== '') {
                query = '?' + query;
            }
        }

        let response;
        let api_resonse;

        response = await fetch((!options.url.includes("History") ? BASE_API_URL : JRA_API_URL) + options.url + query, {
            method: options.method,
            headers: {
                ...options.headers,
            },
            body: typeof options.body === 'string' ? options.body : JSON.stringify(options.body),
        });
        api_resonse = await response.json();

        return {
            success: response.status !== 204 ? api_resonse.success : null,
            status: response.status,
            data: response.status !== 204 && api_resonse.success ? api_resonse.data : null,
        };
    }

    async get(url: string, query: string = "", options: {} = {}) {
        return this.request({ method: 'GET', url, query, ...options });
    }

    async post(url: string, body: {} = {}, options: {} = {}) {
        return this.request({ method: 'POST', url, body, ...options });
    }

    async put(url: string, body: {} = {}, options: {} = {}) {
        return this.request({ method: 'PUT', url, body, ...options });
    }

    async del(url: string, options: {} = {}) {
        return this.request({ method: 'DELETE', url, ...options });
    }
}