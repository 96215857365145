import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import HomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { Link } from 'react-router-dom'

export default function Nav({ pageName } : { pageName: string }) {
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
        setDrawerOpen(!drawerOpen);
    }

    return (
        <div>
            <React.Fragment key={'left'}>
                <div className='offcanvas-menu'>
                    <input 
                        onClick={toggleDrawer} 
                        type="checkbox" 
                        role="button" 
                        aria-label="Display the menu" 
                        className="menu-button" 
                        checked={drawerOpen}  // Utilisez l'état local pour déterminer l'état de la checkbox
                    />
                </div>
                <Drawer
                    anchor={'left'}
                    open={drawerOpen}
                    className='nav'
                    onClose={toggleDrawer}
                >
                    <Box sx={{ width: 300 }}>
                        <List>

                            <ListItem 
                                key={"home"} 
                                disablePadding 
                                className={(pageName === 'home' ? 'active' : '')}
                            >
                                <Link to="/">
                                    <ListItemButton>
                                        <ListItemIcon><HomeIcon /></ListItemIcon>
                                            Accueil
                                    </ListItemButton>
                                </Link>
                            </ListItem>

                            {/* /// */}

                            <ListItem 
                                key={"Contact"} 
                                disablePadding 
                                className={(pageName === 'Contact' ? 'active' : '')}
                            >
                                <Link to="/Contact">
                                    <ListItemButton>
                                        <ListItemIcon><PersonIcon /></ListItemIcon>
                                            Réf contacts uniques
                                    </ListItemButton>
                                </Link>
                            </ListItem>

                            {/* /// */}

                            <ListItem 
                                key={"File"} 
                                disablePadding 
                                className={(pageName === 'File' ? 'active' : '')}
                            >
                                <Link to="/File">
                                    <ListItemButton>
                                        <ListItemIcon><LeaderboardIcon /></ListItemIcon>
                                        Rapports d’intégration
                                    </ListItemButton>
                                </Link>
                            </ListItem>

                            {/* /// */}
                            
                            <ListItem 
                                key={"History"} 
                                disablePadding 
                                className={(pageName === 'History' ? 'active' : '')}
                            >
                                <Link to="/History">
                                    <ListItemButton>
                                        <ListItemIcon><WatchLaterIcon /></ListItemIcon>
                                        Historique
                                    </ListItemButton>
                                </Link>
                            </ListItem>

                        </List>
                    </Box>
                </Drawer>
            </React.Fragment>
        </div>
    )
}