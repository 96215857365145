import '../css/Form.css'

import { useState, useEffect, useRef } from 'react'

import { Contact } from '../services/object/Contact'

import { FormControl } from '@mui/material'
import TextField from '@mui/material/TextField'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button';
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

interface FormProps {
	formContact: Contact;
	onSaveClick: (newContact: Contact, changedFields: string[]) => void;
}

const RadioGroupField = ({
	name,
	defaultValue,
	label,
	onChange
}: {
	name: string;
	defaultValue: string;
	label: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => (
	<div className='checkGroup'>
		<RadioGroup
			name={name}
			defaultValue={defaultValue}
			className='radioGroup form-input'
			onChange={onChange}
		>
			<FormControlLabel value="false" control={<Radio size="small" />} label="" />
			<FormControlLabel value="true" control={<Radio size="small" />} label="" />
		</RadioGroup>
		<p>{label}</p>
	</div>
)

const Form: React.FC<FormProps> = ({ formContact, onSaveClick }) => {
	const [contact, setContact] = useState<Contact>(formContact)
	const [changedFields, setChangedFields] = useState<string[]>([])

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setContact((prevContact) => {
			const updatedContact = { ...prevContact, [name]: value }
			if (!changedFields.includes(name)) {
				setChangedFields([...changedFields, name])
			}
			return updatedContact;
		})
	}

	const handleRadioChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		fieldName: string
	) => {
		const value = event.target.value
		setContact((prevContact) => {
			const updatedContact = { ...prevContact, [fieldName]: value }
			if (!changedFields.includes(fieldName)) {
				setChangedFields([...changedFields, fieldName])
			}
			return updatedContact
		})
	}

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		onSaveClick(contact, changedFields);
	};


	return (
		<FormControl id="form-contact" className="form">
			<div className='content'>
				<div className='infos'>
					<div className='infos-1'>
						<TextField
							fullWidth
							label="Nom"
							size="small"
							defaultValue={formContact?.nom ?? ''}
							name="nom"
							className="form-input"
							onChange={handleChange}
						/>
						<TextField
							fullWidth
							label="Prénom"
							size="small"
							defaultValue={formContact?.prenom ?? ''}
							name="prenom"
							className="form-input"
							onChange={handleChange}
						/>
						<TextField
							fullWidth
							label="Email"
							size="small"
							defaultValue={formContact?.email ?? ''}
							name="email"
							className="form-input"
							onChange={handleChange}
						/>
					</div>
					<div className='infos-2'>
						<div className='tel'>
							<TextField
								fullWidth
								label="Tel fixe"
								size="small"
								defaultValue={formContact?.tel_fixe ?? ''}
								name="tel_fixe"
								className="form-input"
								onChange={handleChange}
							/>
							<TextField
								fullWidth
								label="Tel mobile"
								size="small"
								defaultValue={formContact?.tel_mobile ?? ''}
								name="tel_mobile"
								className="form-input"
								onChange={handleChange}
							/>
						</div>
						<TextField
							fullWidth
							label="Adresse"
							size="small"
							defaultValue={formContact?.adresse ?? ''}
							name="adresse"
							className="form-input"
							onChange={handleChange}
						/>
						<TextField
							fullWidth
							label="Ville"
							size="small"
							defaultValue={formContact?.ville ?? ''}
							name="ville"
							className="form-input"
							onChange={handleChange}
						/>
						<TextField
							fullWidth
							label="Code Postal"
							size="small"
							defaultValue={formContact?.code_postal ?? ''}
							name="code_postal"
							className='codePostal form-input'
							onChange={handleChange}
						/>
					</div>
				</div>

				<div className='checks'>
					<div className='labels'>
						<Tooltip title="Inactif" placement="top">
							<HighlightOffIcon style={{ fill: "var(--red)" }} />
						</Tooltip>
						<Tooltip title="Actif" placement="top">
							<CheckCircleOutlineIcon style={{ fill: "var(--green)" }} />
						</Tooltip>
					</div>
					<RadioGroupField
						name="npai"
						defaultValue={formContact?.npai ?? ''}
						label="NPAI"
						onChange={(event) => handleRadioChange(event, 'npai')}
					/>
					<RadioGroupField
						name="opt_in_adresse"
						defaultValue={formContact?.opt_in_adresse ?? ''}
						label="Optin Adresse"
						onChange={(event) => handleRadioChange(event, 'opt_in_adresse')}
					/>
					<RadioGroupField
						name="opt_in_email"
						defaultValue={formContact?.opt_in_email ?? ''}
						label="Optin Email"
						onChange={(event) => handleRadioChange(event, 'opt_in_email')}
					/>
					<RadioGroupField
						name="opt_in_tel_domicile"
						defaultValue={formContact?.opt_in_tel_domicile ?? ''}
						label="Optin tel domicile"
						onChange={(event) => handleRadioChange(event, 'opt_in_tel_domicile')}
					/>
					<RadioGroupField
						name="opt_in_tel_mobile"
						defaultValue={formContact?.opt_in_tel_mobile ?? ''}
						label="Optin tel mobile"
						onChange={(event) => handleRadioChange(event, 'opt_in_tel_mobile')}
					/>
					<RadioGroupField
						name="infos_vers_pdv"
						defaultValue={formContact?.infos_vers_pdv ?? ''}
						label="Infos vers pdv"
						onChange={(event) => handleRadioChange(event, 'infos_vers_pdv')}
					/>
					<RadioGroupField
						name="infos_vers_partenaire"
						defaultValue={formContact?.infos_vers_partenaire ?? ''}
						label="Infos vers partenaire"
						onChange={(event) => handleRadioChange(event, 'infos_vers_partenaire')}
					/>
					<RadioGroupField
						name="infos_vers_marque"
						defaultValue={formContact?.infos_vers_marque ?? ''}
						label="Infos vers marque"
						onChange={(event) => handleRadioChange(event, 'infos_vers_marque')}
					/>

				</div>
			</div>
			<Button variant="contained" color="jraBlue" onClick={handleSubmit}>
				Enregistrer
			</Button>
		</FormControl>
	);
}

export default Form;



