import '../css/Modal.css'

import { useState, useEffect, useRef } from 'react'

import Service_Api from '../services/Api'
import { Contact } from '../services/object/Contact'
import { frenchContactAttributes } from '../services/object/frenchContactAttributes'
import Form from './FormContact'
import { THEME } from '../Constants'

import { ThemeProvider } from '@mui/material/styles'
import Modal from '@mui/material/Modal';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import CircularProgress from '@mui/material/CircularProgress';

interface ModalContactProps {
	onClose: () => void;
	isModalOpen: boolean;
	formContact: Contact;
	onSave: () => void;
	onSaveError: () => void;
}

export default function ModalContact({
	onClose,
	isModalOpen,
	formContact,
	onSave,
	onSaveError,
}: ModalContactProps) {

	const formRef = useRef<HTMLFormElement>(null)
	const Api = new Service_Api()

	const [open, setOpen] = useState(false)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setOpen(isModalOpen);
	}, [isModalOpen])

	const toCamelCase = (str: string): string => {
		return str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase())
	}

	// actions

	const onSaveClick = async (newContact: Contact, changedFields: string[]) => {
		try {
			const success = await saveFunction(newContact, changedFields);
			if (success) {
				onSave();
			} else {
				onSaveError();
			}
		} catch (error) {
			alert('Une erreur inattendue s\'est produite.');
		}
	}

	const saveFunction = async (newContact: Contact, changedFields: string[]): Promise<boolean> => {
		setLoading(true);

		if (changedFields.length === 0) {
			setLoading(false)
			return false
		}

		const isInvalidLength = (value: string | null, maxLength: number) =>
			value && value.length > maxLength

		const isInvalidEmail = (email: string | null) =>
			email && !/\S+@\S+\.\S+/.test(email)

		const isInvalidPhoneNumber = (phoneNumber: string | null) =>
			phoneNumber && !/\d{10}/.test(phoneNumber)

		const isInvalidCity = (city: string | null) =>
			city && !/^[^0-9]*$/.test(city)

		const isInvalidPostalCode = (postalCode: string | null) =>
			postalCode && !/^\d+$/.test(postalCode)

		const containsNumbers = (value: string | null) => value && /\d/.test(value)

		console.log(
			isInvalidLength(newContact.nom, 50),
			isInvalidLength(newContact.prenom, 50),
			containsNumbers(newContact.nom),
			containsNumbers(newContact.prenom),
			isInvalidEmail(newContact.email),
			isInvalidPhoneNumber(newContact.tel_fixe),
			isInvalidPhoneNumber(newContact.tel_mobile),
			isInvalidCity(newContact.ville),
			isInvalidPostalCode(newContact.code_postal)
		)

		if (
			isInvalidLength(newContact.nom, 50) ||
			isInvalidLength(newContact.prenom, 50) ||
			containsNumbers(newContact.nom) ||
			containsNumbers(newContact.prenom) ||
			isInvalidEmail(newContact.email) ||
			isInvalidPhoneNumber(newContact.tel_fixe) ||
			isInvalidPhoneNumber(newContact.tel_mobile) ||
			isInvalidCity(newContact.ville) ||
			isInvalidPostalCode(newContact.code_postal)
		) {
			alert(
				'Erreur, un des champs n\'est pas valide ! \n' +
				'(Rappel : le prénom et nom ne doivent pas comporter de chiffres ou de caractères spéciaux, ' +
				'le n° de téléphone doit être composé uniquement de chiffres et ' +
				'l\'email doit être valide. \n' +
				'Aucun des champs ne doit dépasser les 50 caractères.)'
			);
			setLoading(false);
			return false;
		} else {
			try {

				// obtenir la valeur par défaut en fonction du champ
				const getDefaultValue = (field: string): string | undefined => {
					switch (field) {
						case 'prenom':
							return 'prenom';
						case 'nom':
							return 'nom';
						case 'email':
							return 'email@email.com';
						case 'tel_mobile':
							return '0600000000';
						case 'tel_fixe':
							return '0200000000';
						case 'adresse':
							return '1 rue de la Rue';
						case 'ville':
							return 'ville';
						case 'code_postal':
							return '00000';
						default:
							return undefined;
					}
				}

				// création des paramètres pour la requête et put du contact
				interface ContactParams {
					[key: string]: string | boolean | undefined;
				}

				console.log(newContact)

				const paramsPutContact: ContactParams = {
					id: newContact.id,
					...changedFields
						.filter((field) => newContact.hasOwnProperty(field))
						.reduce((acc, field) => {
							const camelCaseField = toCamelCase(field)
							const checkIfFieldNotNull = newContact[field] !== undefined && newContact[field] !== null && newContact[field] !== ''
							
							acc[camelCaseField] = checkIfFieldNotNull ? newContact[field]! : getDefaultValue(field)
							acc[`${camelCaseField}IsDeleted`] = !checkIfFieldNotNull
							
							return acc;
						}, {} as ContactParams)
				}

				console.log(paramsPutContact);
				await Api.put('Contact/', paramsPutContact);

				// création des paramètres pour la requête et post de l'historique
				const nom = newContact.nom ?? ''
				const prenom = newContact.prenom ?? ''
				const contactName = `${prenom} ${nom}`.trim()

				for (const field of changedFields) {
					console.log(field)
					const previousData = formContact[field as keyof Contact]
					const safePreviousData = previousData ?? ''

					const historyParams: Record<string, string | undefined | boolean> = {
						contactId: newContact.id,
						contactName: contactName,
						field: frenchContactAttributes[field],
						newData: paramsPutContact[toCamelCase(field)],
						previousData: safePreviousData,
						isDeleted: paramsPutContact[`${toCamelCase(field)}IsDeleted`] || false
					}

					console.log(historyParams)
					await Api.post('History/', historyParams)
				}


				// réinitialisation du formulaire et fermeture du modal
				if (formRef.current) {
					formRef.current.reset()
				}
				onClose()
				setLoading(false)
				return true;
			} catch (error) {
				setLoading(false)
				return false;
			}
		}
	}

	return (
		<div>
			<ThemeProvider theme={THEME}>
				{loading && (<CircularProgress className='loading' />)}
				<Modal
					className='StyledModal'
					aria-labelledby="unstyled-modal-title"
					aria-describedby="unstyled-modal-description"
					open={open}
					onClose={onClose}
				>
					<div className='modal'>
						<button className='close' onClick={onClose}>
							<CloseOutlinedIcon />
						</button>
						<div className='modalHeader'>
							{formContact?.id}  -  {formContact?.prenom} {formContact?.nom}
						</div>
						<hr />
						<div className='modalBody'>
							<Form formContact={formContact} onSaveClick={onSaveClick} />
						</div>
					</div>
				</Modal>
			</ThemeProvider>
		</div>
	)
}
