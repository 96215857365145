import '../css/App.css'
import '../css/Header.css'
import '../css/History.css'

import { useEffect, useState } from 'react'

import Header from '../components/Header'
import FiltersComponent from '../components/Filters'

import Service_Api from '../services/Api'
import { History } from '../services/object/History'
import { Filters } from '../services/object/Filters'
import { formatDateDay, formatDateHours } from '../Constants'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import HistoryIcon from '@mui/icons-material/History';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

export default function HistoryPage() {
	const Api = new Service_Api();
	let pageName = "History";
	let [historyData, setHistoryData] = useState<History>({});

	const fetchHistoryData = async () => {
		try {
			const response = await Api.get("History/");
			setHistoryData(response.data);
			console.log('Données récupérées avec succès', response);
		} catch (error) {
			console.error('Erreur lors de la récupération des données');
		}
	};

	useEffect(() => {
		fetchHistoryData();
	}, []);

	// set filters

	const filters: Filters[] = [
		{ name: 'date', title: "Date", type: 'date' },
		{ name: 'contact_id', title: "Contact ID", type: 'input' },
		{ name: 'editor_uid', title: "Editeur ID", type: 'input' },
	]

	// affichage

	// remplacer les boolean ou les null par du texte
	const formatValue = (value: string | null): string => {
		if (!value || value == null || value == undefined) {
			return "(aucune)";
		} else if (value === "true") {
			return "actif";
		} else if (value === "false") {
			return "inactif";
		} else {
			return value;
		}
	}

	// afficher en subrillance la substring modifiée
	const formatModifiedValue = (previousData: string | null, newData: string | null) => {
		const previousValue = previousData ?? '';
		const newValue = newData ?? '';

		return (
			<span>
				{newValue.split('').map((char, index) => (
					<span key={index} style={{ color: char !== previousValue.charAt(index) ? 'var(--orange)' : '#000' }}>
						{char}
					</span>
				))}
			</span>
		)
	}

	// actions

	const [openDates, setOpenDates] = useState<string[]>([]);

	const toggleDateCard = (date: string) => {
		setOpenDates((prevOpenDates) =>
			prevOpenDates.includes(date) ? prevOpenDates.filter((d) => d !== date) : [...prevOpenDates, date]
		)
	}

	const [openContacts, setOpenContacts] = useState<string[]>([]);

	const toggleContactCard = (contact: string) => {
		setOpenContacts((prevOpenContacts) =>
			prevOpenContacts.includes(contact) ? prevOpenContacts.filter((c) => c !== contact) : [...prevOpenContacts, contact]
		)
	}

	return (
		<div>
			<Header pageName={pageName} />
			<div className="body" id="historyPage">
				<div className="flex">
					<h2>Historique de modifications</h2>
				</div>

				<FiltersComponent<History> pageName={pageName} filters={filters} setHistoryData={setHistoryData} />

				<div className='scroll'>
					<Grid className='flex'>
						{Object.keys(historyData).map((date) => (
							<Card className='date' key={date}>
								<h3 className={`${openDates.includes(date) ? 'open' : 'closed'}`} onClick={() => toggleDateCard(date)}>
									<HistoryIcon fontSize="large" sx={{ color: "var(--jra-blue)" }} />
									{formatDateDay(date)}
									<KeyboardArrowDownIcon className='arrow' />
								</h3>
								<CardContent className={`${openDates.includes(date) ? 'open' : 'closed'}`}>

									{Object.keys(historyData[date]).map((contactId) => (
										<div className='contact' key={contactId}>
											<h4 className={`${openContacts.includes(contactId) ? 'open' : 'closed'}`} onClick={() => toggleContactCard(contactId)}>
												ID {contactId} - {historyData[date][contactId].contact_name}
												<KeyboardArrowDownIcon className='arrow' />
											</h4>

											<div className={`contact-content ${openContacts.includes(contactId) ? 'open' : 'closed'}`}>

												{historyData[date][contactId].ajouts.length > 0 && (
													<div className='histories ajout'>
														<h5>
															<AddIcon />
															Ajouts
														</h5>
														<div className='history-list'>
															{historyData[date][contactId].ajouts.map((ajout, index) => (
																<div className='history' key={index}>
																	<p className='hour'>
																		{formatDateHours(ajout.modified_at)}
																	</p>
																	<p className='value'>
																		{ajout.field} :
																		<span>
																			{formatValue(ajout.new_data)}
																		</span>
																	</p>
																	<p className='editor'>
																		Editeur : {ajout.editor.firstname} {ajout.editor.lastname} ({ajout.editor.job})
																	</p>
																</div>
															))}
														</div>
													</div>
												)}

												{historyData[date][contactId].modifications.length > 0 && (
													<div className='histories modif'>
														<h5>
															<EditOutlinedIcon fontSize="small" />
															Modifications
														</h5>
														<div className='history-list'>
															{historyData[date][contactId].modifications.map((modification, index) => (
																<div className='history' key={index}>
																	<p className='hour'>
																		{formatDateHours(modification.modified_at)}
																	</p>
																	<p className='value'>
																		{modification.field} :
																		<span>
																			{formatValue(modification.previous_data)}
																		</span>
																		<svg height="20px" width="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<g strokeLinecap="round" strokeLinejoin="round"></g> <path d="M4 12H20M20 12L16 8M20 12L16 16" stroke="#000000" strokeWidth="0.8399999999999999" strokeLinecap="round" strokeLinejoin="round"></path>
																		</svg>
																		<span>
																			{formatModifiedValue(modification.previous_data, modification.new_data)}
																		</span>
																	</p>
																	<p className='editor'>
																		Editeur : {modification.editor.firstname} {modification.editor.lastname} ({modification.editor.job})
																	</p>
																</div>
															))}
														</div>
													</div>
												)}

												{historyData[date][contactId].suppressions && historyData[date][contactId].suppressions.length > 0 && (
													<div className='histories suppression'>
														<h5>
															<DeleteOutlinedIcon />
															Suppressions
														</h5>
														<div className='history-list'>
															{historyData[date][contactId].suppressions.map((suppression, index) => (
																<div className='history' key={index}>
																	<p className='hour'>
																		{formatDateHours(suppression.modified_at)}
																	</p>
																	<p className='value'>
																		{suppression.field} :
																		<span>
																			{formatValue(suppression.previous_data)}
																		</span>
																	</p>
																	<p className='editor'>
																		Editeur : {suppression.editor.firstname} {suppression.editor.lastname} ({suppression.editor.job})
																	</p>
																</div>
															))}
														</div>
													</div>
												)}

											</div>
										</div>
									))}
								</CardContent>
							</Card>
						))}
					</Grid>
				</div>
			</div>
		</div>
	)
}